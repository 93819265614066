<template>
   <div class="col-12">
      <div class="card mb-1" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-12">
                  <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ loja.nomeFantasia }}</h1>
                  <p class="font-10 mb-2 limitador-1 text-secondary">
                     <span><i class="far fa-hashtag font-9 me-1"></i>{{ loja.id }}</span>
                     <span class="ms-3">
                        <i class="far fa-tag font-9 me-1"></i>
                        <span>{{ loja.numeroLoja == null || String(loja.numeroLoja).trim().length == 0 ? 'Sem número' : loja.numeroLoja }}</span>
                     </span>
                  </p>
               </div>
               <div class="col-6 col-lg-4">
                  <p class="font-12 mb-0 limitador-1">
                     <i class="far fa-map-marker-alt color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Endereço:</strong>
                     <span> {{ loja.endereco == null || loja.endereco.rua == null || String(loja.endereco.rua).trim().length == 0 ? 'Sem endereço' : (loja.endereco.rua +', '+ loja.endereco.numero +' - '+ loja.endereco.bairro) }}</span>
                  </p>
               </div>
               <div class="col-6 col-lg-4">
                  <p class="font-12 mb-0 limitador-1">
                     <i class="far fa-address-card color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> CNPJ:</strong>
                     <span> {{ loja.cnpj == null || String(loja.cnpj).trim().length == 0 ? 'Sem CNPJ' : loja.cnpj }}</span>
                  </p>
               </div>
               <div class="col-lg-4 d-none d-lg-block">
                  <p class="font-12 mb-0 limitador-1">
                     <i class="far fa-calendar color-theme font-10 me-1"></i><strong class="subtitle me-1"> Venc. Certificado:</strong>
                     <span> {{ loja.dataVencimentoCertificado == null ? 'Sem certificado' : String(loja.dataVencimentoCertificado.dayOfMonth).padStart(2, '0') +'/'+ String(loja.dataVencimentoCertificado.monthValue).padStart(2, '0') +'/'+ loja.dataVencimentoCertificado.year }}</span>
                  </p>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
	name: 'Loja',
   props: ['loja', 'index']
}

</script>